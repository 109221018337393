import {Component, OnInit, signal, WritableSignal} from '@angular/core';

import {ClarityIcons, plusIcon, searchIcon, userIcon} from '@cds/core/icon';
import {WhoamiResponse, WhoamiService} from "../openapi-generated";
import {HttpStateService} from "./services/http-state.service";

ClarityIcons.addIcons(searchIcon, userIcon, plusIcon);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'advaya-search';

  extensionLoaded: WritableSignal<boolean> = signal(true);

  whoami: WritableSignal<WhoamiResponse | null> = signal(null);

  constructor(private whoamiService: WhoamiService, public httpStateService: HttpStateService) {
    setTimeout(() => {
      console.log("Advaya search extension content script loaded", window.document.body.classList);
      if (window.document.body.classList.contains('advaya-extension-loaded')) {
        this.extensionLoaded.set(true);
      } else {
        this.extensionLoaded.set(false);
      }
    }, 5000)
  }

  ngOnInit(): void {
    this.whoamiService.apiWhoamiGet().subscribe(it => {
        if (it.isReady) {
          this.whoami.set(it.data!);
        }
      }
    );
  }
}
