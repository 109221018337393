import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ClarityModule} from "@clr/angular";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {ApiModule, Configuration} from "../openapi-generated";
import {HttpClientModule} from "@angular/common/http";
import {AddDocumentPageComponent} from "./add-document-page/add-document-page.component";
import {ChatComponent} from "./chat/chat.component";
import {SearchDocumentPageComponent} from "./search-document-page/search-document-page.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {GlobalErrorHandler} from "./global-error-handler";


@NgModule({
  declarations: [AppComponent, AddDocumentPageComponent, ChatComponent, SearchDocumentPageComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ClarityModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule.forRoot(() => {
      return new Configuration({
        basePath: ``,
      });
    }),
    NgxExtendedPdfViewerModule,
    FormsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    }
  ]
})
export class AppModule {
}
