<div class="content-container">
  <div class="content-area">
    <div class="clr-row clr-align-items-end	">
      <div class="clr-col-auto">
        <div class="search-input-container">
          <input class="clr-flex-fill" clrInput placeholder="Search documents"/>
        </div>
      </div>
      <div class="clr-col-auto">
        <button class="btn btn-outline btn-sm">GO</button>
      </div>
      <div class="clr-flex-fill"></div>
      <div class="clr-col-auto">
        <a routerLink="/add" class="btn btn-outline btn-sm" style="margin-right: 0">
          <cds-icon shape="plus"></cds-icon>
          Add new document</a>
      </div>
    </div>

    <clr-datagrid
      #datagrid
      (clrDgRefresh)="refresh($event)"
      style="height: 80vh"
    >
      <clr-dg-column>Filename</clr-dg-column>
      <clr-dg-column>Description</clr-dg-column>
      <clr-dg-column>Tags</clr-dg-column>
      <clr-dg-column>Status</clr-dg-column>

      <clr-dg-placeholder>We couldn't find any document!</clr-dg-placeholder>

      <ng-template
        ClrVirtualScroll
        let-doc
        [clrVirtualRowsOf]="documents()"
        [clrVirtualRowsTrackBy]="trackBy"
        (renderedRangeChange)="loadMore($event)"
      >
        <clr-dg-row [clrDgItem]="doc"
                    [clrDgDetailOpenLabel]="'Open details for user ' + doc.id"
                    [clrDgDetailCloseLabel]="'Close details for user' + doc.id"
        >
          <clr-dg-cell>
            @if(doc.id){
              <div style="max-width: 500px">{{ doc.filename }}</div>
            }
            @else{
              <clr-spinner clrSmall></clr-spinner>
            }
          </clr-dg-cell>
          <clr-dg-cell>
            <div style="max-width: 500px">{{ doc.description }}</div>
          </clr-dg-cell>
          <clr-dg-cell>{{ doc.tags | json }}</clr-dg-cell>
          <clr-dg-cell>
            @if (doc.taskStatus == 'ERROR') {
              <span class="label label-danger">Error</span>
              <button class="btn btn-sm" (click)="retry(doc.id)">Retry</button>
            }
            @if (doc.taskStatus == 'PENDING') {
              <span class="label label-info">In progress</span>
            }
            @if (doc.taskStatus == 'DONE') {
              <span class="label label-success">All good</span>
            }
          </clr-dg-cell>
        </clr-dg-row>
      </ng-template>

      <clr-dg-detail *clrIfDetail="let doc">
        <clr-dg-detail-header><a [routerLink]="'/chat/'+doc.id" class="btn btn-outline">Chat with {{ doc.filename }}</a>
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          @if (doc.mime === 'application/pdf') {
            <ngx-extended-pdf-viewer
              height="65vh"
              [showToolbar]="true"
              [zoom]="'page-width'"
              [contextMenuAllowed]="true"
              [src]="'/api/document/pdf/'+doc.id">
            </ngx-extended-pdf-viewer>
          }
        </clr-dg-detail-body>
      </clr-dg-detail>

      <clr-dg-footer>{{ totalCount() }} matching documents</clr-dg-footer>

    </clr-datagrid>

  </div>
</div>
