<div class="content-container" style="height: 100%">
  <div class="content-area" style="height: 100%">
    <div class="clr-row" style="height: 100%">
      <div class="clr-col-6">
        <div class="card" style="margin-top: 0">
          <h3 class="card-header">Chatting with document {{ documentId }}
            <clr-radio-container clrInline>
              <label>Chat model</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="options" required [value]="ChatProviderEnum.Trieve" [(ngModel)]="chatProvider" />
                <label>Trieve</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="options" required [value]="ChatProviderEnum.Gemini" [(ngModel)]="chatProvider" />
                <label>Gemini</label>
              </clr-radio-wrapper>
            </clr-radio-container></h3>

          @for (item of conversation(); track $index) {
            <div class="card-block" [class.answer]="item.backend" [innerHTML]="domSanitizer.sanitize(SecurityContext.HTML ,item.content)">
              {{ item.content }}
            </div>
          }
          @if (pending) {
            <div class="card-block" style="display: flex;justify-content: center;align-items: center;">
            <clr-spinner></clr-spinner>
            </div>
          } @else {
            <div class="card-block" contenteditable="true" #question
                 (keydown.enter)="ask(question.textContent);$event.preventDefault();question.innerHTML=''">
            </div>
            <div class="card-footer">
              <button class="btn btn-sm btn-link" (click)="ask(question.textContent);question.innerHTML=''">Ask!
              </button>
            </div>
          }
        </div>
      </div>
      <div class="clr-col-6">
        <ngx-extended-pdf-viewer
          height="100%"
          [showToolbar]="true"
          [zoom]="'page-width'"
          [contextMenuAllowed]="true"
          [src]="'/api/document/pdf/'+documentId">
        </ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</div>
