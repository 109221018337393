<div class="content-container" style="height: 100%">
  <div class="content-area" style="height: 100%">
    <div class="clr-row" style="height: 100%">
      <div class="clr-col-6">
        <div class="card">
          <div class="card-header">
            Logging
          </div>
          <div class="card-footer">
            <button class="btn btn-outline" (click)="triggerError()">Error</button>
          </div>
        </div>
      </div>
      <div class="clr-col-6">
      </div>
    </div>
  </div>
</div>
