<div class="content-container">
  <div class="content-area">
    <div class="card">
      <div class="card-header">Load Chrome Extension</div>
      <div class="card-block">
        <div class="card-text">
          <p>
            To upload documents to Advaya Search from any web page, install the Chrome Extension.
          </p>
          <p>
            As the extension is not available published on Chrome Web Store, you need to install it manually. The steps
            are the following:
          </p>
          <ul>
            <li>Download the zip archive with the following link: <a class="btn" target="_blank"
                                                                     href="/advaya-search-chrome-extension.zip">Download
              the extension</a></li>
            <li>Extract it somewhere on your disk</li>
            <li>Open the Chrome extensions settings at the url <b>chrome://extensions</b> and enable developer mode (toggle in the top right</li>
            <img src="assets/chrome-extension-settings.png"/>
            <li>Hit the <b>Load unpacked</b> button</li>
            <img src="assets/chrome-extension-load-unpacked.png"/>
            <li>Select the directory you've extracted the archive into</li>
            <li>Pin the extension for easy access</li>
            <img src="assets/chrome-extension-pin.png"/>
            <li>Hit the extension button on any page you'd like to save into Advaya Search</li>
            <img src="assets/chrome-extension-toolbar.png"/>
          </ul>

        </div>
      </div>
    </div>
  </div>
</div>
