import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import ErrorStackParser from 'error-stack-parser';
import { Observable, forkJoin, from, map } from 'rxjs';
import StackTraceGPS from 'stacktrace-gps';
import { LogContent, LogService, LogStacktraceItem } from '../openapi-generated';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public inProgress = false;

  constructor(
    private logService: LogService,
    private injector: Injector,
  ) {}

  handleError(error: any, errorForStack?: any) {
    if (this.inProgress) {
      return;
    }
    // If lazy loading fails, you're not in the middle of something
    // it typically means a new version is available, so we just force reload
    // From https://stackoverflow.com/a/59073053/1448419
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload();
      return;
    }
    try {
      this.logError(error, errorForStack);
    } catch (e) {
      console.error(e);
    }

    console.error(error);
  }

  logError(error: any, errorForStack?: any) {
    const location = this.injector.get(LocationStrategy);

    if (error instanceof HttpErrorResponse && !errorForStack) {
      this.doLogIt(error, location, []);
    } else {
      this.getStackFrames(errorForStack ? errorForStack : error).subscribe(
        (stack) => {
          this.doLogIt(error, location, stack);
        },
        (e) => {
          this.doLogIt(error, location, []);
        },
      );
    }
  }

  private doLogIt(
    error: HttpErrorResponse,
    location: LocationStrategy | PathLocationStrategy,
    stack: LogStacktraceItem[],
  ) {
    const logContent: LogContent = {
      name: error.name || null,
      stacktrace: stack,
      url: location.path(),
      httpStatus: error instanceof HttpErrorResponse ? error.status : undefined,
      gitVersion: 'SHA1',
    };
    this.inProgress = true;
    return this.logService.submitLog(logContent).subscribe(
      (httpState) => {
        if (httpState.isReady) {
          this.inProgress = false;
        }
      },
      () => (this.inProgress = false),
    );
  }

  private getStackFrames(error: any): Observable<LogStacktraceItem[]> {
    const gps = new StackTraceGPS();
    const sourcesObject: Observable<any>[] = ErrorStackParser.parse(error).map((stackFrame) => {
      return forkJoin({
        pinpoint: from(gps.pinpoint(stackFrame)),
        mappedLocation: from(gps.getMappedLocation(stackFrame)),
      }).pipe(
        map((it) => {
          return {
            functionName: it.pinpoint.functionName,
            fileName: it.mappedLocation.fileName,
            lineNumber: it.mappedLocation.lineNumber,
            columnNumber: it.mappedLocation.columnNumber,
          };
        }),
      );
    });
    return forkJoin(sourcesObject);
  }
}
