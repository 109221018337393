/**
 * Advaya Search Web Contract
 * Advaya Search Web Contract
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChatProviderEnum = 'GEMINI' | 'TRIEVE';

export const ChatProviderEnum = {
    Gemini: 'GEMINI' as ChatProviderEnum,
    Trieve: 'TRIEVE' as ChatProviderEnum
};

