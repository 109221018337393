<div class="main-container"  [class.request-in-flight]="httpStateService.inFlightCount() > 0">
  @if (!whoami()) {
    <div class="center-container">
      <clr-spinner>Loading data</clr-spinner>
    </div>
  } @else if (!whoami()?.authenticated) {
    <div class="center-container">
      <a href="/oauth2/authorization/gitlab" class="btn btn-primary">Login</a>
    </div>
  }
  @else if (!whoami()?.authorized){
    <div class="center-container">
      <div>
      <h1>User not authorized</h1>
      <p>Sorry, {{whoami()?.email}} has no access to this app, please contact the administrator to request access.</p>
      </div>
    </div>
  }
  @else {
    <header class="header">
      <a href="/" class="branding">
        <a class="logo-and-title">
          <cds-icon shape="search"></cds-icon>
          <span class="title">Advaya Search</span>
        </a>
      </a>
      <div class="header-nav">
        @if(!extensionLoaded()){
          <a routerLink="/load-extension" class="nav-link nav-text">Load extension !</a>
        }
      </div>
      <div class="header-actions">
        <clr-dropdown>
          <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
            <cds-icon shape="user"></cds-icon>&nbsp;{{ whoami()!.email }}
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <form action="/logout" method="POST" #logoutForm></form>
            <button (click)="logoutForm.submit()" href="/logout" clrDropdownItem>Log out</button>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>

    </header>

    <router-outlet></router-outlet>
  }
</div>
