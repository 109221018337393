import {ChangeDetectorRef, Component, signal, TrackByFunction, WritableSignal} from '@angular/core';
import {DocumentService, SearchResponse, SearchResponseItem} from "../../openapi-generated";
import {ClarityIcons, plusIcon, searchIcon, userIcon} from "@cds/core/icon";
import {ClrDatagridStateInterface} from "@clr/angular";
import {ListRange} from '@angular/cdk/collections';

ClarityIcons.addIcons(searchIcon, userIcon, plusIcon);


@Component({
  selector: 'app-search-document-page',
  templateUrl: './search-document-page.component.html',
  styleUrl: './search-document-page.component.scss'
})
export class SearchDocumentPageComponent {
  documents: WritableSignal<SearchResponseItem[]> = signal([]);
  totalCount: WritableSignal<number> = signal(0);
  trackBy: TrackByFunction<SearchResponseItem> = (index, item) => (item as any)?.index;


  constructor(private documentService: DocumentService, private cdr: ChangeDetectorRef) {
  }


  // Watch out, list range is 0 based and inclusive
  loadMore($event: ListRange) {
    const chunkSize = 20;

    let sliceStart = this.documents().length;
    let sliceEnd = Math.min($event.end + chunkSize, this.totalCount());
    if (sliceEnd < sliceStart + 1) {
      return;
    }
    // First fill in empty documents, so that the list gets updated
    this.documents.update(oldDocs => {
      const data = [...oldDocs]
      for (let i = sliceStart; i < sliceEnd; i++) {
        data.push({index: i + 1} as any);
      }
      return data;
    })

    this.cdr.detectChanges();

    this.documentService.apiDocumentSearchPost({
      searchText: '',
      start: sliceStart,
      end: sliceEnd
    }).subscribe(
      httpState => {
        if (httpState.isReady) {
          this.documents.update(oldDocs => {
            const searchResponse: SearchResponse = httpState.data!;
            let work = [...oldDocs];
            if (work.length > this.totalCount()!) {
              work = work.slice(0, this.totalCount());
            }
            for (let i = sliceStart; i < sliceEnd; i++) {
              const item = searchResponse.items![i - sliceStart]
              work[i] = {...work[i], ...item};
            }
            return work;
          });
          this.cdr.detectChanges();
        }
      }
    );
  }

  refresh($event: ClrDatagridStateInterface<any>) {
    this.documentService.apiDocumentSearchCountPost({
      searchText: '',
      start: 0,
      end: 0
    }).subscribe(httpState => {
      if (httpState.isReady) {
        console.log(httpState.data);
        this.totalCount.set(httpState.data!);
        this.documents.set([]);
        this.loadMore({
          start: 0,
          end: 10
        });
      }
    })
  }

  retry(id: string) {
    this.documentService.apiDocumentDocumentIdRetryTasksPost(id).subscribe();
  }
}
