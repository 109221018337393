import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  builtVersion = 'SHA1';
  newVersionAvailable$$ = signal(false);

  notifyVersion(version?: string | null) {
    if (version) {
      this.newVersionAvailable$$.set(this.builtVersion !== version);
    }
  }
}
