import { Component } from '@angular/core';
import {ClrCommonFormsModule, ClrRadioModule, ClrSpinnerModule} from "@clr/angular";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-demo',
  standalone: true,
    imports: [
        ClrCommonFormsModule,
        ClrRadioModule,
        ClrSpinnerModule,
        NgxExtendedPdfViewerModule,
        ReactiveFormsModule
    ],
  templateUrl: './demo.component.html',
  styleUrl: './demo.component.scss'
})
export class DemoComponent {

  triggerError() {
    throw new Error("Something went wrong");
  }
}
