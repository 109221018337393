import {provideRouter, RouterModule, Routes, withComponentInputBinding, withHashLocation} from "@angular/router";
import {NgModule} from "@angular/core";
import {ChatComponent} from "./chat/chat.component";
import {SearchDocumentPageComponent} from "./search-document-page/search-document-page.component";
import {AddDocumentPageComponent} from "./add-document-page/add-document-page.component";
import {DemoComponent} from "./demo/demo.component";
import {LoadExtensionPageComponent} from "./load-extension-page/load-extension-page.component";

export const routes: Routes = [
  {
    component: ChatComponent,
    path: 'chat/:documentId'
  },
  {
    component: SearchDocumentPageComponent,
    path: 'search'
  },
  {
    component: AddDocumentPageComponent,
    path: 'add'
  },
  {
    component: DemoComponent,
    path: 'demo'
  },
  {
    component: LoadExtensionPageComponent,
    path: 'load-extension'
  },
  {
    path: '',
    redirectTo: '/search',
    pathMatch: 'full',
  }
];

@NgModule({
  providers: [
    provideRouter(routes, withComponentInputBinding(), withHashLocation()),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
