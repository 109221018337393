import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DocumentService, DocumentUploadRequest} from "../../openapi-generated";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-document-page',
  templateUrl: './add-document-page.component.html',
  styleUrl: './add-document-page.component.scss'
})
export class AddDocumentPageComponent {
  form: FormGroup = new FormGroup({
    files: new FormControl<FileList | null>(null)
  });
  pending: boolean = false;

  constructor(private documentService: DocumentService, private router: Router) {
  }


  async submit() {
    this.pending = true;
    const fileList: FileList = this.form.controls['files'].value;
    const request: DocumentUploadRequest[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const base64 = await this.fileToBase64(file);
      request.push({
        base64content: base64,
        filename: file.name,
        mime: file.type
      });
    }
    this.documentService.apiDocumentUploadPost(request).subscribe(httpState => {
      if (httpState.isReady) {
        this.form.reset({files: null});
        this.pending = false;
        this.router.navigateByUrl("/search");
      }
      if(httpState.isError){
        this.pending=false;
      }
    });
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(',')[1]
        || '');
      reader.onerror = error => reject(error);

    });
  }
}
