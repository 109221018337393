import {Component, Input, SecurityContext, signal, WritableSignal} from '@angular/core';
import {ChatProviderEnum, ChatResponse, ChatService} from "../../openapi-generated";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  description: string = '';

  @Input()
  documentId: string = '';

  pending = false;

  conversation: WritableSignal<ChatResponse[]|any> = signal([]);

  constructor(private chatService: ChatService, public domSanitizer: DomSanitizer) {

  }

  ask(question: string | null) {
    this.conversation.update(it => [...it, {content: question!}]);
    this.pending = true;
    this.chatService.apiChatPost({
      content: question!,
      documentIdList: [this.documentId],
      provider: this.chatProvider
    }).subscribe(data => {
      if(data.isReady) {
        this.pending = false;
        this.conversation.update(it => [...it, {...data.data, backend: true}]);
      }
    })
  }

  protected readonly SecurityContext = SecurityContext;
  chatProvider: ChatProviderEnum = ChatProviderEnum.Trieve;
  protected readonly ChatProviderEnum = ChatProviderEnum;
}
