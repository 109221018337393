<div class="content-container">
  <div class="content-area">
    <div class="card">
      <div class="card-header">Add a document</div>
      <div class="card-block">
        <div class="card-text">
          <form clrForm clrLayout="vertical" [formGroup]="form">
            <clr-file-input-container>
              <label>File to submit</label>
              <input type="file" formControlName="files" multiple required clrFileInput />
              <clr-control-helper>Submit a pdf</clr-control-helper>
            </clr-file-input-container>
          </form>
        </div>
      </div>
      <div class="card-footer">
        @if(pending){
          <clr-spinner clrSmall></clr-spinner>
        }
        @else{
        <button class="btn btn-sm btn-link" [disabled]="form.invalid" (click)="submit()">Send</button>
        }
      </div>
    </div>
  </div>
</div>
