import { Component } from '@angular/core';
import {ClrCommonFormsModule, ClrFileInputModule, ClrSpinnerModule} from "@clr/angular";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-load-extension-page',
  standalone: true,
    imports: [
        ClrCommonFormsModule,
        ClrFileInputModule,
        ClrSpinnerModule,
        ReactiveFormsModule
    ],
  templateUrl: './load-extension-page.component.html',
  styleUrl: './load-extension-page.component.scss'
})
export class LoadExtensionPageComponent {

}
