import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppAvailabilityService {
  available$$ = signal(true);

  setStatus(available: boolean) {
    this.available$$.set(available);
  }
}
